<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('show_pronostico')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <DashCard title="Gráfico" title-icon="timeline" body-class="pa-0">
                    <div class="text-center">
                        <v-btn-toggle mandatory class="pa-2" @change="load_chart">
                            <v-btn value="mp10">
                                <span class="subtitle-2">MP10</span>
                            </v-btn>
                            <v-btn value="wind">
                                <span class="subtitle-2"><v-icon left>toys</v-icon>Viento</span>
                            </v-btn>
                            <v-btn value="temp">
                                <span class="subtitle-2"><v-icon left>whatshot</v-icon>Temp.</span>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                    <v-divider />
                    <EmptyCard class="ma-4" v-if="grafico_error" text="Este gráfico no está disponible en este momento" icon="error_outline" />
                    <v-skeleton-loader v-else class="ma-4" type="image, image" :loading="chart_loading">
                        <ChartPronostico :height="350" :series="series" />
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
            <v-col cols="12" md="4" lg="3">
                <DashCard title="Controles" title-icon="settings">
                    <v-label>Escoja una fecha para ver los datos</v-label>
                    <div class="mb-4">
                        <DatePickerText v-model="fecha" dense flat />
                    </div>
                    <div class="ma-n1 text-center">
                        <v-btn class="ma-1" depressed color="secondary" @click="cargar_pronostico(true)">
                            <span class="subtitle-2"><v-icon left>timelapse</v-icon>Ver fecha específica</span>
                        </v-btn>
                        <v-btn class="ma-1" depressed color="secondary" @click="cargar_pronostico(false)">
                            <span class="subtitle-2"><v-icon left>today</v-icon>Ver el día de hoy</span>
                        </v-btn>
                    </div>
                </DashCard>
            </v-col>
            <v-col cols="12" md="8" lg="9">
                <DashCard title="Pronóstico Semanal" title-icon="today" body-class="pa-0">
                    <EmptyCard class="ma-4" v-if="list_error" text="Estos datos no están disponibles" icon="error_outline" />
                    <v-skeleton-loader v-else class="ma-4" type="image" :loading="loading">
                        <v-slide-group>
                            <v-slide-item v-for="(item, i) in index" v-bind:key="i">
                                <v-card outlined class="ma-2" width="250">
                                    <v-card-text class="pa-0 white--text light-blue">
                                        <v-row dense class="text-center">
                                            <v-col cols="12">
                                                <span class="subtitle-1 font-weight-bold">{{ item.sun_rise_time | moment("dddd DD, MMMM YYYY") }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-divider />
                                    </v-card-text>
                                    <v-card-title :class="'pa-0 ma-0 ' + get_color(item.mp10_alert.code)">
                                        <v-flex text-center class="py-2">
                                            <span class="d-block subtitle-2 white--text">{{ item.siteinfo.name }}</span>
                                            <span class="d-block title-2 white--text">{{ item.mp10_alert.code }}</span>
                                        </v-flex>
                                    </v-card-title>
                                    <v-card-text class="pa-0 text-center text--primary">
                                        <v-row dense>
                                            <v-col cols="12">
                                                <span class="subtitle-1 font-weight-bold">{{ item.mp10_mean }} ug/m<sup>3</sup></span>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <span class="font-weight-bold d-block">Mín.</span>
                                                <span>{{ item.temp_min }}º C</span>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <span class="font-weight-bold d-block">Máx.</span>
                                                <span>{{ item.temp_max }}º C</span>
                                            </v-col>
                                            <v-col cols="12">
                                                Viento de hasta {{ item.windspeed_max }} m/s
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <template v-if="item.horarios_criticos.length > 0">
                                        <v-divider />
                                        <v-list-group prepend-icon="warning">
                                            <template v-slot:activator>
                                                <v-list-item-title class="font-weight-bold">Alertas</v-list-item-title>
                                            </template>
                                            <v-divider />
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-row dense class="text-center">
                                                        <v-col>
                                                            <v-chip :color="item.mp10_alert_hourly.bcolor">
                                                            <span class="d-block font-weight-bold">
                                                                {{ item.mp10_alert_hourly.name }} ({{ item.mp10_alert_hourly.lower }} ~ {{ item.mp10_alert_hourly.upper }} ug/m<sup>3</sup>)
                                                            </span>
                                                            </v-chip>
                                                        </v-col>
                                                        <v-col cols="12" v-for="(horario, i) in item.horarios_criticos" v-bind:key="i">
                                                            <span class="subtitle-1 font-weight-bold">{{ horario.horario }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-group>
                                    </template>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers';
import DatePickerText from '@/shared/components/DatePickerText'
import DashCard from '@/shared/components/DashCard'
import EmptyCard from '@/shared/components/EmptyCard'
import ChartPronostico from '@/dash/components/ChartPronostico'
import AccessMessage from '@/dash/components/AccessMessage'
import moment from 'moment'

export default {
    components: {
        DatePickerText,
        DashCard,
        EmptyCard,
        ChartPronostico,
        AccessMessage
    },
    data: () => ({
        xaxis_conf: {
            type: 'datetime',
            labels: {
                formatter: (val) => moment(val).add(4, "hour").format("L HH:mm")
            },
            tooltip: {
                formatter: (val) => moment(val).add(4, "hour").format("L HH:mm")
            }
        },
        index: null,
        grafico: null,
        list_error: false,
        grafico_error: false,
        fecha: moment().format("YYYY-MM-DD"),
        timestamp: null
    }),
    computed: {
        loading() {
            return !this.index && !this.list_error;
        },
        chart_loading() {
            return !this.grafico && !this.grafico_error;
        },
        series() {
            if(!this.grafico) return [];

            let series = this.grafico.series;
            series.forEach(s => s.type = 'line');
            return series;
        }
    },
    mounted() {
        this.$emit('title', { text: "Pronosticos", icon: "timeline" });

        if (!this.$parent.$parent.get_permission('show_pronostico')) return;
        
        this.cargar_pronostico();
    },
    methods: {
        cargar_pronostico(custom) {
            this.list_error = false; 
            this.index = null;
            this.grafico = null;

            if(!custom) {
                this.timestamp = null;
                this.fecha = moment().format("YYYY-MM-DD");
                axiosHelper.get('meteodata/pronosticos/')
                    .then(response => this.index = response.data)
                    .catch(error => this.list_error = true);
            } else {
                console.log(this.timestamp);
                axiosHelper.post('meteodata/pronosticos/', { fecha: this.fecha })
                    .then(response => this.index = response.data)
                    .catch(error => this.list_error = true);
            }
            this.load_chart('mp10');
        },
        get_color(code) {
            if(code == 'FAVORABLE') return 'light-green'
            if(code == 'ALERTA') return 'orange'
            if(code == 'CRITICO') return 'red'

            return ''
        },
        load_chart(tipo) {
            axiosHelper.post('meteodata/pronosticos/grafico/', { variable: tipo, fecha: this.fecha })
                .then(response => {
                    this.grafico_error = false; 
                    this.grafico = response.data;
                })
                .catch(error => this.grafico_error = true);
        }
    }
}
</script>